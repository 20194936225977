import React from 'react';
import cx from 'classnames';
import { Icon } from '../Icons';
import styles from './style.module.css';

interface Props {
  errorMessage: string;
}

export const Error = ({ errorMessage }: Props) => {
  const errorMainClass = cx(
    'mt24 mb16 flex items-center ba br3 w-100 pv12 purpley-pink b--purpley-pink',
    styles.errorMainClass,
  );
  return (
    <div className={errorMainClass}>
      <Icon className="ml12 mr8" name="SvgIconWarning" height={21} width={21} />
      <p className="ma0 fw7 f13 lh-copy" aria-live="polite">
        {errorMessage}
      </p>
    </div>
  );
};
