import React from 'react';
import cx from 'classnames';
import styles from './CardContainer.module.css';

interface Props {
  children?: React.ReactNode;
}
export class CardContainer extends React.Component<Props> {
  render() {
    const container = cx('bg-white center br10', styles.containerCard);
    const { children } = this.props;
    if (!children) {
      return null;
    }
    return <div className={container}>{children}</div>;
  }
}
