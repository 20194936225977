import React from 'react';
import cx from 'classnames';
import { Icon } from '../Icons';
import styles from './Welcome.module.css';

interface Props {
  title: string;
  paragraph: string;
}

export const WelcomeWithTitle = ({ title, paragraph }: Props) => {
  const containerParagraph = cx('mt8 ml40', styles.containerParagraph);
  return (
    <div className="flex flex-column justify-center items-start h-100 w-100">
      <div className="mb72 ml40">
        <Icon name="SvgIconLogo" />
      </div>
      <div className={containerParagraph}>
        <p className="f32 lh-title ma0  abbey fw2 ttu">{title}</p>
        <p className="b f32 lh-title ma0 abbey fw7 ttu">{paragraph}</p>
      </div>
    </div>
  );
};
