import React from 'react';
import cx from 'classnames';
import { Banner } from '../../components/Banner';
import { Container } from '../../components/Container';
import { ContainerSigninSignup } from './ContainerSigninSignup';
import styles from './Login.module.css';

interface Props {
  type: string;
}

export const Login = ({ type }: Props) => {
  const containerSigninSignup = cx(
    styles.absoluteContainer,
    'absolute-m absolute-l br10 flex items-start items-center-m items-center-l flex-columns',
  );

  return (
    <>
      <Banner />
      <Container className={containerSigninSignup}>
        <ContainerSigninSignup type={type} />
      </Container>
    </>
  );
};
