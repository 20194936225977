import { axiosInstance } from '../../../../../services/axios-instance';
import { removePhoneMask, startWithZero } from '../../../../../utils/mask';

export const User = {
  create: (values: any) => {
    const { name, phone, email, password } = values;

    const payload = {
      name,
      phone: startWithZero(removePhoneMask(phone)),
      email,
      password,
    };

    return axiosInstance.post('/user', payload, {
      headers: { 'Content-Type': 'application/json' },
    });
  },
};
