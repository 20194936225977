import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { navigate } from 'gatsby';
import { LinkBlack } from '../../../components/Link';
import { WelcomeWithoutTitle, WelcomeWithTitle } from '../../../components/Welcome';
import { SignIn } from '../components/SignIn';
import { SignUp } from '../components/SignUp';
import styles from './ContainerSigninSignup.module.css';

interface Props {
  type: string;
}

const getDestionationPage = (urlSearch: string) => {
  if (/redirectTo/g.test(urlSearch)) {
    const [, redirectTo] = urlSearch.split('=');
    if (redirectTo) {
      return decodeURIComponent(redirectTo);
    }
  }
  return;
};

export const ContainerSigninSignup = ({ type }: Props) => {
  const activePage = /cadastro/g.test(type);
  const redirectTo = getDestionationPage(type);
  const [isActive, setIsActive] = useState(false);

  const containterSignInSignUp = cx(
    'flex center br10 bg-white relative top--2',
    styles.containterSignInSignUp,
  );
  const formContainer = 'absolute-m absolute-l h-100 top-0';
  const welcomeCard = cx('dn db-m db-l left-0 w-50 w-50-l z-1', formContainer);
  const announceYourVehicle = cx('dn db-m db-l right-0 w-50-m  w-50-l z-2', formContainer);
  const overlay = cx('relative-m relative-l h-100', styles.overlay, styles.transitionEaseInOut, {
    [styles.overlayActive]: isActive,
  });
  const overlayContainer = cx(
    'absolute-m absolute-l top-0 w-100 w-50-m  w-50-l h-100 overflow-hidden z-5',
    styles.left50,
    styles.transitionEaseInOut,
    {
      [styles.overlayContainerActive]: isActive,
    },
  );
  const overlayPanel = cx(
    'absolute-m absolute-l top-0 w-100 flex-m flex-l items-center w-50-m w-50-l h-100',
    styles.transitionEaseInOut,
  );
  const overlayRight = cx('right-0', overlayPanel, {
    dn: isActive,
  });
  const overlayLeft = cx(overlayPanel, {
    dn: !isActive,
  });

  const onClick = () => {
    if (!isActive) {
      navigate('/login?cadastro');
    } else {
      navigate('/login');
    }

    setIsActive(!isActive);
  };

  const SignUpForm = isActive && (
    <div className={overlayLeft}>
      <SignUp
        key="CriarConta"
        title="Criar conta"
        name="SvgIconLogo"
        width={132}
        height={66}
        onClick={onClick}
      />
    </div>
  );

  const SignInForm = !isActive && (
    <div className={overlayRight}>
      <SignIn
        key="Login"
        title="Login"
        name="SvgIconLogo"
        width={132}
        height={66}
        onClick={onClick}
        redirectTo={redirectTo}
      />
    </div>
  );

  const SignInWelcomeTitle = !isActive && (
    <div className={welcomeCard}>
      <WelcomeWithTitle title="Seja bem-vindo" paragraph="ao maior site de veículos da região." />
    </div>
  );

  const SignUpWelcomeTitle = isActive && (
    <div className={announceYourVehicle}>
      <WelcomeWithoutTitle title="anuncie seu veículo" paragraph="para milhares de pessoas." />
    </div>
  );

  const link = cx('ttu fw6 mb4 mb8-ns db pl16 pl72-ns pl0-m pl0-l pointer absolute', styles.link);
  const navigateHome = () => navigate('/');
  const LinkBackHome = (
    <LinkBlack
      className={link}
      onClick={navigateHome}
      title="Voltar para a página inicial"
      tabIndex={0}
    >
      voltar ao início
    </LinkBlack>
  );

  useEffect(() => {
    if (typeof window !== 'undefined' && activePage) {
      setIsActive(true);
    }
  }, []);

  return (
    <div className={containterSignInSignUp}>
      {LinkBackHome}
      {SignInWelcomeTitle}
      {SignUpWelcomeTitle}
      <div className={overlayContainer}>
        <div className={overlay}>
          {SignUpForm}
          {SignInForm}
        </div>
      </div>
    </div>
  );
};

ContainerSigninSignup.displayName = 'C_SigninSignup';
