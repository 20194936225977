import React from 'react';
import { PageProps } from 'gatsby';
import { Layout } from '../components/Layout';
import { Login } from '../modules/Login';

const SandBoxLogin = ({ ...props }: PageProps) => (
  <Layout
    title="Login | Autoscar"
    description="Faça login na sua conta Autoscar e crie agora mesmo um anúncio para vender seu carro, moto, ônibus e outros veículos.
  "
  >
    <Login type={props.location.search} />
  </Layout>
);

export default SandBoxLogin;
