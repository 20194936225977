import React, { FormEvent, ChangeEvent, useState } from 'react';
import cx from 'classnames';
import { Field } from 'formik';
import { Button } from '../../../../components/Button';
import { Checkbox } from '../../../../components/Checkbox';
import { Link } from '../../../../components/Link';
import { removeSpecialCharacters } from '../../../../utils/mask';
import { InputForm } from '../InputForm/InputForm';

interface Props {
  submitError?: boolean;
  errors: any;
  values: any;
  title: string;
  isSubmitting: boolean;
  genericError: boolean;
  handleChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  handleSubmit?: (event: FormEvent<HTMLFormElement>) => void;
  onClick?: () => void;
}
const customInputComponent = ({
  errorMessageUser,
  errorMessagePass,
  divClass,
  field,
  ...props
}: any) => {
  const normalizeFieldData = {
    name: field.name,
    onBlur: field.onBlur,
    onChange: field.onChange,
    errormessage:
      (!!props.form.touched[field.name] && props.form.errors[field.name]) ||
      errorMessageUser ||
      errorMessagePass,
  };
  const errorsInput = cx({
    mb16:
      !props.form.touched[field.name] ||
      (!props.form.errors[field.name] && !!props.form.touched[field.name]),
    mb8: !!props.form.touched[field.name] && props.form.errors[field.name],
  });

  return (
    <div className={errorsInput}>
      <InputForm {...normalizeFieldData} {...props} />
    </div>
  );
};

export const SignInForm = ({
  title,
  handleSubmit,
  handleChange,
  values,
  onClick,
  submitError,
  isSubmitting,
}: Props) => {
  const formMainClass = cx('w-100 mb72', {
    'mt24 mt40-m mt40-l': !submitError,
  });
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && onClick) {
      onClick();
    }
  };
  const [shouldStayConnected, setShouldStayConnected] = useState(false);

  const handleShouldStayConnected = () => {
    setShouldStayConnected(!shouldStayConnected);
  };

  return (
    <form onSubmit={handleSubmit} className={formMainClass}>
      <Field
        name="email"
        id={title + 'user-email'}
        labeltext="E-mail"
        type="text"
        iconleft="SvgIconMail"
        value={values.email}
        onChange={handleChange}
        component={customInputComponent}
        validateOnChange={false}
        required
        mask={removeSpecialCharacters}
      />
      <Field
        name="password"
        id={title + 'user-password'}
        labeltext="Senha"
        iconleft="SvgIconLock"
        iconright="SvgIconShowMe"
        iconTitle="Exibir/Esconder senha"
        iconrightchange="SvgIconHideMe"
        type="password"
        typeinputchange="text"
        value={values.password}
        onChange={handleChange}
        component={customInputComponent}
        validateOnChange={false}
        required
      />

      <div className="mb12 mt4">
        <Link to="/esqueci-senha" title="Esqueci minha senha">
          Esqueci minha senha
        </Link>
      </div>
      <div className="flex items-center mb24">
        <Checkbox
          id={title + 'permanecer-conectado'}
          textLabel="Permanecer conectado"
          checked={shouldStayConnected}
          onChange={handleShouldStayConnected}
          value="PermanecerConectado"
        />
      </div>
      <div className="flex flex-column w-100">
        <Button type="submit" loading={isSubmitting} aria-label="Entrar">
          Entrar
        </Button>
        <span className="f16 abbey trueno fw3 mt24 mb8 flex items-center">
          É novo por aqui?
          <Link
            className="ml4 f16 fw7 pointer"
            onClick={onClick}
            title="Criar conta"
            tabIndex={0}
            onKeyDown={handleKeyDown}
          >
            Criar conta
          </Link>
        </span>
      </div>
    </form>
  );
};
