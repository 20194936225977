import React, { FormEvent, Component } from 'react';
import cx from 'classnames';
import { Form, Field } from 'formik';
import { Button } from '../../../../components/Button';
import { Link } from '../../../../components/Link';
import { cellPhone, onlyLetters, removeSpecialCharacters } from '../../../../utils/mask';
import { InputForm } from '../InputForm/InputForm';
import { ValidateExistingEmail } from './services/ValidateExistingEmail';

interface Props {
  title: string;
  errors: any;
  values: any;
  touched: any;
  isSubmitting: boolean;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit?: (event: FormEvent<HTMLFormElement>) => void;
  onClick?: () => void;
}

export class SignUpForm extends Component<Props, any> {
  state = {
    errorMessage: '',
  };
  onValidateEmail = async (value: string, onBlur: any, errors: any) => {
    await onBlur(value);
    if (!errors) {
      const response = await ValidateExistingEmail.validateEmail(value)
        .then(res => res)
        .catch(error => {
          return error.response;
        });
      if (response && response.status === 409) {
        return this.setState({ errorMessage: response.data.message });
      }
      return this.setState({ errorMessage: '' });
    }
  };

  customInputComponent = ({ divClass, field, errorMessage, ...props }: any) => {
    let handleBlur = field.onBlur;
    if (field.name === 'email') {
      handleBlur = () => {
        props.form.setFieldTouched('email', true);
        this.onValidateEmail(field.value, field.onBlur, props.form.errors[field.name]);
      };
    }

    const normalizeFieldData = {
      name: field.name,
      onBlur: handleBlur,
      onChange: field.onChange,
      errormessage:
        (!!props.form.touched[field.name] && props.form.errors[field.name]) || errorMessage,
    };

    const errorsInput = cx({
      mb16:
        !props.form.touched[field.name] ||
        (!props.form.errors[field.name] && !!props.form.touched[field.name] && !errorMessage),
      mb8: (!!props.form.touched[field.name] && props.form.errors[field.name]) || errorMessage,
    });

    return (
      <div className={errorsInput}>
        <InputForm {...normalizeFieldData} {...props} />
      </div>
    );
  };
  validateEmail = () => {
    return this.state.errorMessage;
  };
  onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (this.state.errorMessage) {
      this.setState({ errorMessage: '' });
    }
    const { handleChange } = this.props;
    if (handleChange) {
      handleChange(event);
    }
  };
  handleKeyDown = (event: React.KeyboardEvent) => {
    const { onClick } = this.props;
    if (event.key === 'Enter' && onClick) {
      onClick();
    }
  };
  render() {
    const { handleChange, values, title, onClick, isSubmitting } = this.props;
    const { errorMessage } = this.state;

    return (
      <Form className="w-100 mb40">
        <span className="f16-ns f14 abbey trueno fw3 mb16 flex items-center">
          Você é uma empresa?
          <Link to="/cadastro-pj" className="ml4 f16-ns f14 fw7" title="Cadastrar pessoa juridica">
            Clique aqui
          </Link>
        </span>

        <Field
          name="name"
          id={title + 'user-name'}
          labeltext="Nome"
          type="text"
          iconleft="SvgIconName"
          value={values.name}
          onChange={handleChange}
          component={this.customInputComponent}
          validateOnChange={false}
          mask={onlyLetters}
          required
        />

        <Field
          name="phone"
          id={title + 'user-phone'}
          labeltext="Telefone"
          iconleft="SvgIconPhone"
          type="tel"
          value={values.phone}
          onChange={handleChange}
          mask={cellPhone}
          component={this.customInputComponent}
          validateOnChange={false}
          required
        />

        <Field
          name="email"
          id={title + 'user-email'}
          labeltext="E-mail"
          type="text"
          iconleft="SvgIconMail"
          value={values.email}
          onChange={this.onEmailChange}
          component={this.customInputComponent}
          validateOnChange={false}
          errorMessage={errorMessage}
          validate={this.validateEmail}
          required
          mask={removeSpecialCharacters}
        />

        <Field
          name="password"
          id={title + 'user-password'}
          labeltext="Senha"
          iconleft="SvgIconLock"
          iconright="SvgIconShowMe"
          iconrightchange="SvgIconHideMe"
          iconId="exibir-senha"
          iconTitle="Exibir/Esconder senha"
          type="password"
          typeinputchange="text"
          value={values.password}
          onChange={handleChange}
          component={this.customInputComponent}
          validateOnChange={false}
          required
        />

        <Field
          name="confirmPassword"
          id={title + 'confirm-password'}
          labeltext="Confirmar senha"
          iconleft="SvgIconLock"
          iconright="SvgIconShowMe"
          iconrightchange="SvgIconHideMe"
          iconId="exibir-senha-repetida"
          iconTitle="Exibir/Esconder senha"
          type="password"
          typeinputchange="text"
          value={values.confirmPassword}
          onChange={handleChange}
          component={this.customInputComponent}
          required
        />

        <div className="mb24" />

        <div className="flex flex-column w-100">
          <Button type="submit" loading={isSubmitting} aria-label="Criar conta">
            Criar conta
          </Button>

          <span className="f16-ns f14 abbey trueno fw3 mt24 mb8 flex items-center">
            Já possui cadastro?
            <Link
              className="ml4 f16-ns f14 fw7 pointer"
              onClick={onClick}
              title="Fazer login"
              tabIndex={0}
              onKeyDown={this.handleKeyDown}
            >
              Fazer login
            </Link>
          </span>
        </div>
      </Form>
    );
  }
}
