import React, { useState } from 'react';
import classnames from 'classnames';
import styles from './style.module.css';

interface Props {
  id: string;
  textLabel?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement> | React.KeyboardEvent) => void;
  value?: any;
  checked?: boolean;
  name?: string;
  qtd?: number;
}

export const Checkbox = ({ id, textLabel, checked, name, value, qtd, ...props }: Props) => {
  const [pulseAnimationOnGoing, setPulseAnimationOnGoing] = useState(false);
  const onClick = () => setPulseAnimationOnGoing(false);
  const onAnimationEnd = () => setPulseAnimationOnGoing(false);
  const sectionClass = classnames(styles.checkbox, 'relative dib');
  const labelClass = classnames(styles.checkboxTitle, 'f14', 'ml8', 'trueno', 'fw3', 'abbey');

  const checkboxRectClass = classnames(
    styles.checkboxRect,
    'flex justify-center items-center w1 h1 ba b--gray',
    {
      [styles.pulseAnimation]: pulseAnimationOnGoing,
    },
  );

  const renderQtd = () => {
    if (name !== 'stateVehicle' && qtd) {
      return <label className="f14 fw3 red dib"> ({qtd})</label>;
    }

    return null;
  };

  const labelToRender = textLabel && (
    <span tabIndex={0} className={labelClass} role="checkbox" aria-checked="false">
      {textLabel} {renderQtd()}
    </span>
  );

  const handleKeyDown = (event: React.KeyboardEvent) => {
    const { onChange } = props;
    if (event.key === 'Enter' && onChange) {
      onChange(event);
    }
  };

  return (
    <div className={sectionClass}>
      <label
        htmlFor={id}
        className="flex items-center pointer"
        onClick={onClick}
        onAnimationEnd={onAnimationEnd}
        onKeyDown={handleKeyDown}
      >
        <input
          className="dn _hasChecked"
          type="checkbox"
          name={name}
          id={id}
          checked={checked}
          value={value}
          {...props}
        />
        <div className={checkboxRectClass}>
          <div className="flex justify-center items-center" />
        </div>
        {labelToRender}
      </label>
    </div>
  );
};
