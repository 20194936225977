import React, { Component } from 'react';
import { Formik } from 'formik';
import { navigate } from 'gatsby';
import { inject, observer } from 'mobx-react';
import { Error } from '../../../../components/Error';

import { Grid } from '../../../../components/Grid';
import { GridCell } from '../../../../components/GridCell';

import { ModalSuccessAndError } from '../../../../components/ModalSuccessAndError';
import { validationFormLogin } from '../../../../utils/validationForm';
import { CardContainer } from '../CardContainer';
import { LogoIcon } from '../LogoIcon/LogoIcon';
import { TitleForm } from '../TitleForm';
import { SignInForm } from './SignInForm';

interface Props {
  name: string;
  title: string;
  width: number;
  height: number;
  user?: any;
  errorMessageUser?: string;
  onClick?: () => void;
  redirectTo?: string;
}
@inject('user')
@observer
export class SignIn extends Component<Props> {
  state = {
    openYourModal: false,
    loginOrPassError: false,
  };

  componentDidMount(): void {
    this.props.user.cleanUser();
  }

  closeYourModal = () => this.setState({ openYourModal: false });

  handleSubmit = async (values: any, actions: any) => {
    const { user, redirectTo } = this.props;
    const response = await user.postAuthUser(values);

    if (response && response.status === 200) {
      if (redirectTo) {
        return navigate(redirectTo);
      }
      const path = user.profile.admin ? '/gerenciamento-usuarios' : '/meus-anuncios';
      return navigate(path);
    }
    if (response && response.status !== 200) {
      this.setState({ loginOrPassError: true });
    }
    if (!response) {
      this.setState({ openYourModal: true });
    }

    await actions.setSubmitting(false);
  };

  renderSignInForm = (props: any) => {
    const { title, onClick } = this.props;
    const { loginOrPassError } = this.state;
    return <SignInForm onClick={onClick} title={title} submitError={loginOrPassError} {...props} />;
  };

  render() {
    const { title } = this.props;
    const { openYourModal, loginOrPassError } = this.state;
    return (
      <CardContainer>
        <LogoIcon {...this.props} />
        <Grid className="h-100-m h-100-l justify-center items-center">
          <GridCell width={[1, 1 / 2, 1]} className="pl16 pr16 pa0-ns pl40-m pr40-m pl48-l pr48-l">
            <TitleForm title={title} focusTime={600} />
            {loginOrPassError && <Error errorMessage="E-mail e/ou senha inválido(s)" />}
            <Formik
              initialValues={{ password: '', email: '' }}
              onSubmit={this.handleSubmit}
              validationSchema={validationFormLogin}
              // tslint:disable-next-line: jsx-no-lambda
              render={props => this.renderSignInForm(props)}
            />
          </GridCell>
        </Grid>
        <ModalSuccessAndError
          title="Ops!!"
          subTitle="ocorreu um erro inesperado"
          isOpen={openYourModal}
          closeModal={this.closeYourModal}
          textBtnClose="Ok"
        />
      </CardContainer>
    );
  }
}
