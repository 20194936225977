import React, { Component } from 'react';
import { Formik } from 'formik';
import { navigate } from 'gatsby';
import { Grid } from '../../../../components/Grid';
import { GridCell } from '../../../../components/GridCell';
import { ModalSuccessAndError } from '../../../../components/ModalSuccessAndError';
import { validationFormSignUp } from '../../../../utils/validationForm';
import { CardContainer } from '../CardContainer';
import { LogoIcon } from '../LogoIcon/LogoIcon';
import { TitleForm } from '../TitleForm';
import { User } from './services/User';
import { SignUpForm } from './SignUpForm';

interface Props {
  name: string;
  title: string;
  width: number;
  height: number;
  onClick?: () => void;
}

interface State {
  isModalOpen: boolean;
  modalData: {
    status: boolean;
    modalTitle: string;
    subTitle: string;
    text: string;
    textBtnClose: string;
    actionBtn?: () => void;
  };
}

export class SignUp extends Component<Props, State> {
  state = {
    isModalOpen: false,
    modalData: {
      status: false,
      modalTitle: '',
      subTitle: '',
      text: '',
      textBtnClose: '',
      actionBtn: () => {
        return;
      },
    },
  };

  handleModalStatus = () => {
    this.setState({ isModalOpen: false });
  };

  redirect = () => {
    navigate('/selecionar-tipo-de-anuncio');
  };

  renderModal = (success: boolean) => {
    if (success) {
      const { onClick } = this.props;
      return this.setState({
        isModalOpen: true,
        modalData: {
          status: true,
          modalTitle: 'Parabéns',
          subTitle: 'Conta criada com sucesso',
          text: 'Seja bem-vindo e bons negócios!',
          textBtnClose: 'Acessar conta',
          actionBtn: onClick,
        },
      });
    }
    return this.setState({
      isModalOpen: true,
      modalData: {
        status: false,
        modalTitle: 'Ops!',
        subTitle: 'Erro ao criar sua conta',
        text: 'Tente novamente mais tarde!',
        textBtnClose: 'Voltar',
      },
    });
  };

  handleSubmit = async (values: any, actions: any) => {
    try {
      const response = await User.create(values);
      if (response.status === 200 || response.status === 201) {
        actions.resetForm();
        return this.renderModal(true);
      }
    } catch (e) {
      actions.resetForm();
      return this.renderModal(false);
    }
    await actions.setSubmitting(false);
  };

  render() {
    const { title, onClick } = this.props;
    const {
      isModalOpen,
      modalData: { status, modalTitle, subTitle, text, textBtnClose, actionBtn },
    } = this.state;
    return (
      <CardContainer>
        <LogoIcon {...this.props} />
        <Grid className="h-100-m h-100-l justify-center items-center">
          <GridCell width={[1, 1 / 2, 1]} className="pl16 pr16 pa0-ns pl40-m pr40-m pl48-l pr48-l">
            <TitleForm title={title} />
            <Formik
              initialValues={{ password: '', email: '', name: '', phone: '', confirmPassword: '' }}
              onSubmit={this.handleSubmit}
              validationSchema={validationFormSignUp}
              // tslint:disable-next-line: jsx-no-lambda jsx-no-multiline-js
              render={props => <SignUpForm onClick={onClick} title={title} {...props} />}
            />
          </GridCell>
          <ModalSuccessAndError
            success={status}
            title={modalTitle}
            subTitle={subTitle}
            text={text}
            isOpen={isModalOpen}
            closeModal={this.handleModalStatus}
            textBtnClose={textBtnClose}
            actionBtn={actionBtn}
          />
        </Grid>
      </CardContainer>
    );
  }
}
